:root {
  --color-background-main: #18181b;
  --color-background-secondary: #272c38;
  --color-text-main: #ffffff;
}

html,
body,
#root {
  height: 100%;
}

img.twemoji {
  height: 1em;
  width: auto;
  display: inline-block;
}

.tiptap img {
  height: 1em;
  width: auto;
  display: inline-block;
}

p.is-editor-empty:first-child::before {
  color: #717171;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror:focus {
  outline: none;
}

/* Load Bebas Neue Font Pack */
@font-face {
  font-family: 'Bebas Neue';
  src: local('BebasNeue-Regular'), url('assets/fonts/BebasNeue/BebasNeue-Regular.ttf') format('truetype');
  font-weight: 400;
}

/* Load Poppins Font Pack */
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-Thin'), url('assets/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-ThinItalic'), url('assets/fonts/Poppins/Poppins-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-ExtraLight'), url('assets/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-ExtraLightItalic'), url('assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-Light'), url('assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-LightItalic'), url('assets/fonts/Poppins/Poppins-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-Italic'), url('assets/fonts/Poppins/Poppins-Italic.ttf') format('truetype');
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-Regular'), url('assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-Medium'), url('assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-MediumItalic'), url('assets/fonts/Poppins/Poppins-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-SemiBold'), url('assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-SemiBoldItalic'), url('assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-Bold'), url('assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-BoldItalic'), url('assets/fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-ExtraBold'), url('assets/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-ExtraBoldItalic'), url('assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-Black'), url('assets/fonts/Poppins/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins-BlackItalic'), url('assets/fonts/Poppins/Poppins-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

/* Load Montserrat Font Pack */
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Thin'), url('assets/fonts/Montserrat/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-ThinItalic'), url('assets/fonts/Montserrat/Montserrat-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-ExtraLight'), url('assets/fonts/Montserrat/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-ExtraLightItalic'),
    url('assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Light'), url('assets/fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-LightItalic'), url('assets/fonts/Montserrat/Montserrat-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Italic'), url('assets/fonts/Montserrat/Montserrat-Italic.ttf') format('truetype');
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Regular'), url('assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Medium'), url('assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-MediumItalic'), url('assets/fonts/Montserrat/Montserrat-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-SemiBold'), url('assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-SemiBoldItalic'),
    url('assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Bold'), url('assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-BoldItalic'), url('assets/fonts/Montserrat/Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-ExtraBold'), url('assets/fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-ExtraBoldItalic'),
    url('assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Black'), url('assets/fonts/Montserrat/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-BlackItalic'), url('assets/fonts/Montserrat/Montserrat-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background-main);
  color: var(--color-text-main);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*
 * Custom scrollbar
 */

/* width */
.custom-scrollbar::-webkit-scrollbar,
#chat-textfield::-webkit-scrollbar,
body::-webkit-scrollbar,
.MuiBox-root::-webkit-scrollbar {
  width: 16px; /* width of the entire scrollbar */
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track,
#chat-textfield::-webkit-scrollbar-track,
body::-webkit-scrollbar-track,
.MuiBox-root::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
.MuiBox-root::-webkit-scrollbar-thumb {
  background-color: #aaaaaa; /* color of the scroll thumb */
  border-radius: 8px; /* roundness of the scroll thumb */
  border: 4px solid var(--color-background-main); /* creates padding around scroll thumb */
}

#chat-textfield::-webkit-scrollbar-thumb {
  background-color: #aaaaaa; /* color of the scroll thumb */
  border-radius: 8px; /* roundness of the scroll thumb */
  border: 4px solid var(--color-background-secondary); /* creates padding around scroll thumb */
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover,
#chat-textfield::-webkit-scrollbar-thumb:hover,
body::-webkit-scrollbar-thumb:hover,
.MuiBox-root::-webkit-scrollbar-thumb:hover {
  background: #717171;
}
